import DetailsPage from 'components/DetailsPage';
import Breadcrumb from 'components/DetailsPage/Breadcrumb';
import { RootState } from 'core/store';
import { contactByIdSelector } from 'core/useCases/contacts/selectors';
import { getContactLabel } from 'core/useCases/contacts/utils';
import useResponsive from 'hooks/responsive';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getNumericDate } from 'utils/dateTimeFormat';
import Activities from './Activities';
import Information from './Information';
import LinkedApplications from './LinkedApplications';
import RecommendedContacts from './RecommendedContacts';
import { SBreadcrumbRight } from './styles';

type ContactDetailsProps = {
    id: string;
};

const ContactDetails: FC<ContactDetailsProps> = ({ id }) => {
    const { t } = useTranslation();
    const contact = useSelector((state: RootState) => contactByIdSelector(state, id));
    const { isMobile } = useResponsive();

    return (
        <div>
            {contact && (
                <DetailsPage
                    activities={<Activities contact={contact} />}
                    breadcrumb={
                        <>
                            {!isMobile && (
                                <Breadcrumb
                                    linkLabel={t('contact-details.my-network')}
                                    linkPath="/contacts"
                                    secondLabel={getContactLabel(contact, t)}
                                />
                            )}
                            <SBreadcrumbRight>{`${t('contact-details.added')} ${getNumericDate(
                                contact.createdDate,
                            )}`}</SBreadcrumbRight>
                        </>
                    }
                    information={<Information contact={contact} />}
                    secondaryActivities={<LinkedApplications contactId={id} />}
                    secondaryInformation={<RecommendedContacts contactId={id} />}
                />
            )}
        </div>
    );
};

export default ContactDetails;
