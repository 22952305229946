import { sharedFieldStyles } from 'components/Forms/lib/styles';
import deviceSize from 'constants/deviceSize';
import styled from 'styled-components';
import { SColumn, SRow, SSpacedRow } from 'styles/layout';

export const SActions = styled(SRow)`
    gap: 1rem;
`;

export const SWrapper = styled(SColumn)`
    gap: 3rem;
`;

export const SCard = styled.div<{ isEven: boolean }>`
    background-color: ${({ theme, isEven }) => (isEven ? theme.color.grey.lighter : theme.color.white)};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const SCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const SCardHeaderLeft = styled.div`
    display: flex;
    gap: 1rem;
`;

export const SColumnResponsive = styled(SColumn)`
    @media (max-width: ${deviceSize.mobile}px) {
        flex-direction: row;
        align-items: baseline;
        margin-bottom: 1rem;

        span {
            margin-left: 1rem;
        }
    }
`;

export const SSpacedRowResponsive = styled(SSpacedRow)`
    @media (max-width: ${deviceSize.mobile}px) {
        flex-direction: column;
    }
`;

export const SSearchInput = styled.div`
    width: 23rem;
    position: relative;

    input {
        ${sharedFieldStyles}
        height: 4rem;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;

export const SPremiumDiv = styled.div`
    background-color: ${({ theme }) => theme.color.premium.main};
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-60%, 20%);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SRight = styled(SRow)`
    gap: 1rem;
`;

export const SRightResponsive = styled(SRight)`
    @media (max-width: ${deviceSize.mobile}px) {
        flex-direction: column;

        a {
            width: 100%;
        }
    }
`;

export const SCardHeaderStatus = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
`;

export const SCardLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SCardLineLeft = styled.span`
    color: ${({ theme }) => theme.color.grey.light};
    font-weight: bold;
`;

export const SCardLineRight = styled.span``;
