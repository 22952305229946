import styled from 'styled-components';

export const FloatingCup = styled.img`
    position: absolute;
    right: 0;
    top: 50px;

    @media (max-width: 1024px) {
        display: none;
    }
`;

export const Cup = styled.img`
    @media (max-width: 500px) {
        display: none;
    }
`;
