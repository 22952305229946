import styled from 'styled-components';

export const SLabel = styled.label`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin-bottom: 3px;
    font-weight: bold;
    color: ${({ theme }) => theme.color.text.main};
`;

export const SPremiumDiv = styled.div`
    background-color: ${({ theme }) => theme.color.premium.main};
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
