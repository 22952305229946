import User from 'core/models/user';
import { RootState } from 'core/store';
import UserProfile from '../../models/userProfile';

export const userSelector = (state: RootState): User | null => state.session.user;

export const userIdSelector = (state: RootState): string | null => state.session.user?.id || null;

export const userProfileSelector = (state: RootState): UserProfile | null => {
    const { user } = state.session;
    if (!user) {
        return null;
    }

    return {
        civility: user.civility,
        createdDate: user.createdDate,
        email: user.email,
        firstLoginDate: user.firstLoginDate,
        firstName: user.firstName,
        hasPaid: user.hasPaid,
        job: user.job,
        lastLoginDate: user.lastLoginDate,
        lastName: user.lastName,
        mobileNumber: user.mobileNumber,
        phoneNumber: user.phoneNumber,
        status: user.status,
        taskNotifications: user.taskNotifications,
    };
};

export const isLoggedIn = (state: RootState): boolean => Boolean(state.session.user);
