/* eslint-disable max-len */
import Button from 'components/Button';
import CardWithTitle from 'components/CardWithTitle';
import { SDiagramsColomnResponsive } from 'components/Dashboard/styles';
import Layout from 'components/Layout';
import BACKEND_URL from 'constants/backendUrl';
import { useTranslation } from 'react-i18next';
import { SBoldSecondaryText, SSubCard } from 'styles/components';
import { SCenteredColumn, SSpacedRowResponsive } from 'styles/layout';
import { Cup, FloatingCup } from './styles';

const VideoCard = ({ imageUrl, videoLink }: { imageUrl: string; videoLink: string }) => {
    const { t } = useTranslation();

    return (
        <SSubCard style={{ backgroundColor: 'white', position: 'relative' }}>
            <a href={videoLink} rel="noreferrer" target="_blank">
                <img alt={t('page.ask-subscription.click-video')} src={imageUrl} />
                <img
                    alt=""
                    aria-hidden="true"
                    height={70}
                    src="/premium/play-youtube.svg"
                    style={{ left: '50%', position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)' }}
                    width={100}
                />
            </a>
        </SSubCard>
    );
};

const AskSubscriptionPage = () => {
    const { t } = useTranslation();

    const handleRedirectToPayment = () => {
        window.location.href = `${BACKEND_URL}/api/payment/session`;
    };

    return (
        <Layout pageTitle={t('page.ask-subscription.meta-title')}>
            <div style={{ position: 'relative' }}>
                <SCenteredColumn>
                    <h1>
                        <SBoldSecondaryText>{t('page.ask-subscription.title')}</SBoldSecondaryText>
                    </h1>
                    <p>
                        <SBoldSecondaryText>{t('page.ask-subscription.content.part1')}</SBoldSecondaryText>
                        <br />
                        <span>{t('page.ask-subscription.content.part2')}</span>
                        <br />
                        <span>{t('page.ask-subscription.content.part3')}</span>
                        <SBoldSecondaryText>{t('page.ask-subscription.content.part4')}</SBoldSecondaryText>
                        <span>{t('page.ask-subscription.content.part5')}</span>
                        <SBoldSecondaryText>{t('page.ask-subscription.content.part6')}</SBoldSecondaryText>
                        <span>{t('page.ask-subscription.content.part7')}</span>
                        <SBoldSecondaryText>{t('page.ask-subscription.content.part8')}</SBoldSecondaryText>
                    </p>
                    <Button onClick={handleRedirectToPayment}>{t('page.ask-payment.payment-button')}</Button>
                    <FloatingCup alt="coffee" height={147} src="/premium/cup.png" width={121} />
                    <br />
                    <br />
                </SCenteredColumn>
                <SDiagramsColomnResponsive>
                    {/* Import de Contacts */}
                    <SSpacedRowResponsive>
                        <CardWithTitle label={t('page.ask-subscription.block-import.title')}>
                            <p>
                                <span>{t('page.ask-subscription.block-import.content.part1')}</span>{' '}
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-import.content.part2')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-import.content.part3')}</span>
                                <br />
                                <ul style={{ listStyleType: 'initial', paddingLeft: '30px' }}>
                                    <li>
                                        {t('page.ask-subscription.block-import.content.part4')}{' '}
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            height={16}
                                            src="/premium/linkedin.png"
                                            width={16}
                                        />
                                    </li>
                                    <li>
                                        {t('page.ask-subscription.block-import.content.part5')}{' '}
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            height={16}
                                            src="/premium/outlook.png"
                                            width={16}
                                        />
                                    </li>
                                    <li>
                                        {t('page.ask-subscription.block-import.content.part6')}{' '}
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            height={16}
                                            src="/premium/gmail.png"
                                            width={16}
                                        />
                                    </li>
                                    <li>{t('page.ask-subscription.block-import.content.part7')}</li>
                                </ul>
                                <br />
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-import.content.part8')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-import.content.part9')}</span>{' '}
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-import.content.part10')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-import.content.part11')}</span>
                            </p>
                        </CardWithTitle>
                        <VideoCard
                            imageUrl="/premium/import-contacts.png"
                            videoLink="https://www.youtube.com/watch?v=ZC9X-iLTmbM"
                        />
                    </SSpacedRowResponsive>
                    {/* Recherche de Contacts */}
                    <SSpacedRowResponsive>
                        <CardWithTitle label={t('page.ask-subscription.block-search-contacts.title')}>
                            <p>
                                <span>{t('page.ask-subscription.block-search-contacts.content.part1')}</span>{' '}
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-search-contacts.content.part2')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-search-contacts.content.part3')}</span>
                                <br />
                                <ul style={{ listStyleType: 'initial', paddingLeft: '30px' }}>
                                    <li>{t('page.ask-subscription.block-search-contacts.content.part4')}</li>
                                    <li>{t('page.ask-subscription.block-search-contacts.content.part5')}</li>
                                    <li>{t('page.ask-subscription.block-search-contacts.content.part6')}</li>
                                    <li>{t('page.ask-subscription.block-search-contacts.content.part7')}</li>
                                </ul>
                                <br />
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-search-contacts.content.part8')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-search-contacts.content.part9')}</span>
                            </p>
                        </CardWithTitle>
                        <VideoCard
                            imageUrl="/premium/search-contacts.png"
                            videoLink="https://www.youtube.com/watch?v=Go7gAqmuMwI"
                        />
                    </SSpacedRowResponsive>
                    {/* Recherche d'Activités */}
                    <SSpacedRowResponsive>
                        <CardWithTitle label={t('page.ask-subscription.block-search-activities.title')}>
                            <p>
                                <span>{t('page.ask-subscription.block-search-activities.content.part1')}</span>{' '}
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-search-activities.content.part2')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-search-activities.content.part3')}</span>
                                <br />
                                <ul style={{ listStyleType: 'initial', paddingLeft: '30px' }}>
                                    <li>{t('page.ask-subscription.block-search-activities.content.part4')}</li>
                                    <li>{t('page.ask-subscription.block-search-activities.content.part5')}</li>
                                    <li>{t('page.ask-subscription.block-search-activities.content.part6')}</li>
                                </ul>
                                <br />
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-search-activities.content.part7')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-search-activities.content.part8')}</span>
                            </p>
                        </CardWithTitle>
                        <VideoCard
                            imageUrl="/premium/search-activities.png"
                            videoLink="https://www.youtube.com/watch?v=Go7gAqmuMwI"
                        />
                    </SSpacedRowResponsive>
                    {/* Recherche d'Opportunités */}
                    <SSpacedRowResponsive>
                        <CardWithTitle label={t('page.ask-subscription.block-search-opportunities.title')}>
                            <p>
                                <span>{t('page.ask-subscription.block-search-opportunities.content.part1')}</span>{' '}
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-search-opportunities.content.part2')}
                                </SBoldSecondaryText>
                                <br />
                                <br />
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-search-opportunities.content.part3')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-search-opportunities.content.part4')}</span>
                            </p>
                        </CardWithTitle>
                        <VideoCard
                            imageUrl="/premium/search-opportunities.png"
                            videoLink="https://www.youtube.com/watch?v=Go7gAqmuMwI"
                        />
                    </SSpacedRowResponsive>
                    {/* Filtres */}
                    <SSpacedRowResponsive>
                        <CardWithTitle label={t('page.ask-subscription.block-filters.title')}>
                            <p>
                                <span>{t('page.ask-subscription.block-filters.content.part1')}</span> <br />
                                <ul style={{ listStyleType: 'initial', paddingLeft: '30px' }}>
                                    <li>{t('page.ask-subscription.block-filters.content.part2')}</li>
                                    <li>
                                        {t('page.ask-subscription.block-filters.content.part3')}{' '}
                                        <SBoldSecondaryText>
                                            {t('page.ask-subscription.block-filters.content.statut')}
                                        </SBoldSecondaryText>
                                        <ul style={{ listStyleType: 'initial', paddingLeft: '30px' }}>
                                            <li>
                                                <SBoldSecondaryText>
                                                    {t('page.ask-subscription.block-filters.content.part4')}
                                                </SBoldSecondaryText>
                                            </li>
                                            <li>
                                                <SBoldSecondaryText>
                                                    {t('page.ask-subscription.block-filters.content.part5')}
                                                </SBoldSecondaryText>
                                            </li>
                                            <li>
                                                <SBoldSecondaryText>
                                                    {t('page.ask-subscription.block-filters.content.part6')}
                                                </SBoldSecondaryText>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        {t('page.ask-subscription.block-filters.content.part7')}{' '}
                                        <SBoldSecondaryText>
                                            {t('page.ask-subscription.block-filters.content.statut')}
                                        </SBoldSecondaryText>
                                    </li>
                                </ul>
                                <br />
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-filters.content.part8')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-filters.content.part9')}</span>
                            </p>
                        </CardWithTitle>
                        <VideoCard
                            imageUrl="/premium/filters.png"
                            videoLink="https://www.youtube.com/watch?v=ifr0wBjOq-s"
                        />
                    </SSpacedRowResponsive>
                    {/* Objectifs */}
                    <SSpacedRowResponsive>
                        <CardWithTitle label={t('page.ask-subscription.block-objectives.title')}>
                            <p>
                                <span>{t('page.ask-subscription.block-objectives.content.part1')}</span>{' '}
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-objectives.content.part2')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-objectives.content.part3')}</span>
                                <ul style={{ listStyleType: 'initial', paddingLeft: '30px' }}>
                                    <li>{t('page.ask-subscription.block-objectives.content.part4')}</li>
                                    <li>{t('page.ask-subscription.block-objectives.content.part5')}</li>
                                    <li>{t('page.ask-subscription.block-objectives.content.part6')}</li>
                                </ul>
                                <br />
                                <SBoldSecondaryText>
                                    {t('page.ask-subscription.block-objectives.content.part7')}
                                </SBoldSecondaryText>{' '}
                                <span>{t('page.ask-subscription.block-objectives.content.part8')}</span>
                            </p>
                        </CardWithTitle>
                        <VideoCard
                            imageUrl="/premium/objectives.png"
                            videoLink="https://www.youtube.com/watch?v=uvEdhfBhESQ"
                        />
                    </SSpacedRowResponsive>
                </SDiagramsColomnResponsive>
                <SCenteredColumn>
                    <p>
                        <br />
                        <br />
                        <SBoldSecondaryText>{t('page.ask-subscription.content.part9')}</SBoldSecondaryText>{' '}
                        <span>{t('page.ask-subscription.content.part10')}</span>
                        <br />
                        <br />
                        <SSpacedRowResponsive>
                            <Cup alt="coffee" height={147} src="/premium/cup.png" width={121} />
                            <div style={{ alignSelf: 'center' }}>
                                <Button onClick={handleRedirectToPayment}>
                                    {t('page.ask-payment.payment-button')}
                                </Button>
                            </div>
                            <Cup alt="coffee" height={147} src="/premium/cup.png" width={121} />
                        </SSpacedRowResponsive>
                    </p>
                </SCenteredColumn>
            </div>
        </Layout>
    );
};

export default AskSubscriptionPage;
