import BACKEND_URL from 'constants/backendUrl';
import Tag from 'core/models/tag';
import { FC, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { GET } from 'utils/http';
import Label from '../Label';
import { SWrapper } from './styles';

type TagsInputProps = {
    control: Control<any>;
    name: string;
    isPremium: boolean;
    onClickToPremiumField: () => void;
};

const getTagsRequest = async (query: string): Promise<Tag[]> => {
    const response = (await GET<Tag[]>(`${BACKEND_URL}/api/tags/search/${query}`)) as Tag[];

    return response;
};

const TagsInput: FC<TagsInputProps> = ({ control, name, isPremium, onClickToPremiumField }) => {
    const { t } = useTranslation();
    const [tags, setTags] = useState<Tag[]>([]);
    const options = tags.map((tag) => ({ label: tag.name, value: tag.id }));

    const fetchTags = async (query?: string) => {
        setTags(await getTagsRequest(query || ''));
    };

    useEffect(() => {
        fetchTags();
    }, []);

    return (
        <SWrapper isPremium={isPremium} onClick={onClickToPremiumField}>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                    <>
                        <Label isPremium={isPremium} name={name} text={t('common.tags.label')} />
                        <CreatableSelect
                            formatCreateLabel={(inputValue) => `Ajouter le libellé : ${inputValue}`}
                            isDisabled={!isPremium}
                            noOptionsMessage={() => null}
                            onChange={(values) => {
                                onChange(values.map((tag) => ({ id: tag.value, name: tag.label })) || []);
                            }}
                            options={options}
                            placeholder={t('common.tags.placeholder')}
                            value={value ? value.map((tag: Tag) => ({ label: tag.name, value: tag.id })) : []}
                            isMulti
                        />
                    </>
                )}
            />
        </SWrapper>
    );
};

export default TagsInput;
