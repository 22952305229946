import Label from 'components/Forms/lib/Label';
import Tag from 'core/models/tag';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SSubCard } from 'styles/components';
import { SWrapper } from './styles';

type TagsInformationProps = {
    tags: Tag[];
};

const TagsInformation: FC<TagsInformationProps> = ({ tags }) => {
    const { t } = useTranslation();
    const history = useHistory();

    if (!tags || !tags.length) {
        return null;
    }

    const handleTagClick = (tag: Tag) => {
        history.push(`/contacts`, { tag });
    };

    return (
        <SSubCard>
            <Label text={t('contact-details.tags')} />
            <SWrapper>
                {tags.map((tag) => (
                    <a key={tag.name} href="/contacts" onClick={() => handleTagClick(tag)}>
                        {tag.name}
                    </a>
                ))}
            </SWrapper>
        </SSubCard>
    );
};

export default TagsInformation;
