import Tooltip from 'components/Tooltip';
import { ReactComponent as Premium } from 'images/whiteIcons/Premium.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SLabel, SPremiumDiv } from './styles';

interface LabelProps {
    name?: string;
    text: string;
    required?: boolean;
    helper?: string;
    isPremium?: boolean;
}

const Label: FC<LabelProps> = ({ name, text, required, helper, isPremium }) => {
    const { t } = useTranslation();

    return (
        <SLabel htmlFor={name}>
            {`${t(text)} ${required ? '*' : ''}`}
            {helper && <Tooltip label={helper} />}
            {isPremium === false && (
                <SPremiumDiv>
                    <Premium />
                </SPremiumDiv>
            )}
        </SLabel>
    );
};

export default Label;
