import deviceSize from 'constants/deviceSize';
import styled from 'styled-components';

export const SWrapper = styled.div<{ isPremium: boolean }>`
    position: relative;
    width: 23rem;
    box-sizing: border-box;
    cursor: ${({ isPremium }) => (isPremium ? 'default' : 'pointer')};
    input {
        height: 2.6rem;
    }

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;

export const SPremiumDiv = styled.div`
    background-color: ${({ theme }) => theme.color.premium.main};
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-70%, 15%);
    display: flex;
    justify-content: center;
    align-items: center;
`;
