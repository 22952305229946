import InterviewType from './interviewType';
import MeetingType from './meetingType';

enum EventType {
    Interview = 'interview',
    Meeting = 'event',
    TradeShowVisit = 'trade-show-visit',
}

export default EventType;

export const EventTypeWithoutApplication = [
    InterviewType.Presentation,
    InterviewType.JobInvestigation,
    InterviewType.MarketAppropriateness,
    InterviewType.Targeting,
    InterviewType.JobInterviewPreparation,
    InterviewType.Coffee,
    InterviewType.Meal,
    MeetingType.Afterwork,
    MeetingType.Workshop,
    MeetingType.Conference,
    MeetingType.Exhibition,
    MeetingType.Training,
    MeetingType.Webinar,
    InterviewType.Other,
];

export const EventTypeWithApplication = [InterviewType.PreSelection, InterviewType.Recruitment];

export const EventTypeWithoutContact = [
    MeetingType.Afterwork,
    MeetingType.Workshop,
    MeetingType.Conference,
    MeetingType.Exhibition,
    MeetingType.Training,
    MeetingType.Webinar,
];
