import { sharedFieldStyles } from 'components/Forms/lib/styles';
import deviceSize from 'constants/deviceSize';
import styled from 'styled-components';
import { SRow } from 'styles/layout';

export const SRight = styled(SRow)`
    gap: 1rem;
`;
export const SRightResponsive = styled(SRight)`
    @media (max-width: ${deviceSize.mobile}px) {
        flex-direction: column;
        width: 100%;

        a {
            width: 100%;
        }
    }
`;

export const SSearchInput = styled.div`
    width: 23rem;
    position: relative;

    input {
        ${sharedFieldStyles}
        height: 4rem;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;

export const SPremiumDiv = styled.div`
    background-color: ${({ theme }) => theme.color.premium.main};
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-60%, 20%);
    display: flex;
    justify-content: center;
    align-items: center;
`;
