import BACKEND_URL from 'constants/backendUrl';
import Tag from 'core/models/tag';
import { ReactComponent as Premium } from 'images/whiteIcons/Premium.svg';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { GET } from 'utils/http';
import { SPremiumDiv, SWrapper } from './styles';

type TagsFilterProps = {
    onChange: (tags: Tag[]) => void;
    tagFilters: Tag[];
    isPremium: boolean;
    onClickToPremiumField: () => void;
};

const getTagsRequest = async (query: string): Promise<Tag[]> => {
    const response = (await GET<Tag[]>(`${BACKEND_URL}/api/tags/search/${query}`)) as Tag[];

    return response;
};

const TagsFilter: FC<TagsFilterProps> = ({ onChange, tagFilters, isPremium, onClickToPremiumField }) => {
    const { t } = useTranslation();
    const [tags, setTags] = useState<Tag[]>([]);
    const options = tags.map((tag) => ({ label: tag.name, value: tag.id }));

    const fetchTags = async (query?: string) => {
        setTags(await getTagsRequest(query || ''));
    };

    useEffect(() => {
        fetchTags();
    }, []);

    return (
        <SWrapper isPremium={isPremium} onClick={onClickToPremiumField}>
            <Select
                isDisabled={!isPremium}
                onChange={(values) => {
                    onChange(values.map((tag) => ({ id: tag.value, name: tag.label })) || []);
                }}
                options={options}
                placeholder={t('common.tags.placeholder')}
                value={tagFilters ? tagFilters.map((tag: Tag) => ({ label: tag.name, value: tag.id })) : []}
                isMulti
            />
            {!isPremium && (
                <SPremiumDiv>
                    <Premium />
                </SPremiumDiv>
            )}
        </SWrapper>
    );
};

export default TagsFilter;
