import Label from 'components/Forms/lib/Label';
import ResourceType from 'core/models/resource';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SSubCard } from 'styles/components';
import { SWrapper } from './styles';

type ResourceInformationProps = {
    resources: Array<ResourceType>;
};

const ResourceInformation: FC<ResourceInformationProps> = ({ resources }) => {
    const { t } = useTranslation();

    if (!resources || !resources.length) {
        return null;
    }

    return (
        <SSubCard>
            <Label text={t('contact-details.resources')} />
            <SWrapper>
                {resources.map(({ url, label }) => (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a key={`${url}${label}`} href={url} rel="noopener" target="_blank">
                        {label}
                    </a>
                ))}
            </SWrapper>
        </SSubCard>
    );
};

export default ResourceInformation;
