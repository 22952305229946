import styled from 'styled-components';
import { SRow } from 'styles/layout';

export const SWrapper = styled(SRow)`
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;

    a {
        text-decoration: underline;
    }
`;
